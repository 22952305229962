.swagger-ui {
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui tr.response {
    padding-bottom: 20px
}

.swagger-ui td.col.response-col_status {
    padding-bottom: 20px
}

.swagger-ui td.col.response-col_description {
    padding-bottom: 20px;
    padding-top: 0
}

.swagger-ui td.col.response-col_links {
    padding-bottom: 20px
}

.swagger-ui .opblock-tag {
    font-size: 24px;
    margin: 0 0 5px;
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui .opblock-tag small {
    font-size: 14px;
    font-weight: 400;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 0 10px;
    font-family: sans-serif;
    color: #002b5a
}

h3.opblock-tag span {
    color: #007bb8
}

.swagger-ui .parameter__type {
    font-size: 12px;
    padding: 5px 0;
    font-family: monospace;
    font-weight: 600;
    color: #002b5a
}

.swagger-ui .opblock .opblock-section-header>label {
    font-size: 12px;
    font-weight: 700;
    margin: 0;
    margin-left: auto;
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui .opblock .opblock-section-header h4 {
    font-size: 14px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0;
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui .opblock .opblock-summary-operation-id,
.swagger-ui .opblock .opblock-summary-path,
.swagger-ui .opblock .opblock-summary-path__deprecated {
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    word-break: break-word;
    padding: 0 10px;
    font-family: monospace;
    font-weight: 600;
    color: #002b5a
}

.swagger-ui .opblock .opblock-summary-description {
    font-size: 13px;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    word-break: break-word;
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui .opblock.opblock-post {
    border-color: #44c244;
    background: rgba(32, 77, 32, .1)
}

.swagger-ui .opblock.opblock-post .opblock-summary-method {
    background: #44c244
}

.swagger-ui .opblock.opblock-post .opblock-summary {
    border-color: #44c244
}

.swagger-ui .opblock.opblock-post .tab-header .tab-item.active h4 span:after {
    background: #44c244
}

.swagger-ui .opblock.opblock-get {
    border-color: #002b5a;
    background: rgba(13, 92, 172, .1)
}

.swagger-ui .opblock.opblock-get .opblock-summary-method {
    background: #002b5a
}

.swagger-ui .opblock.opblock-get .opblock-summary {
    border-color: #002b5a
}

.swagger-ui .opblock.opblock-get .tab-header .tab-item.active h4 span:after {
    background: #002b5a
}

.swagger-ui .opblock.opblock-options {
    border-color: #61affe;
    background: rgba(97, 175, 254, .1)
}

.swagger-ui .opblock.opblock-options .opblock-summary-method {
    background: #61affe
}

.swagger-ui .opblock.opblock-options .opblock-summary {
    border-color: #61affe
}

.swagger-ui .opblock.opblock-options .tab-header .tab-item.active h4 span:after {
    background: #61affe
}

.swagger-ui .tab li {
    font-size: 12px;
    min-width: 60px;
    padding: 0;
    cursor: pointer;
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui .opblock-description-wrapper,
.swagger-ui .opblock-external-docs-wrapper,
.swagger-ui .opblock-title_normal {
    font-size: 12px;
    margin: 0 0 5px;
    padding: 15px 20px;
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui .opblock-description-wrapper h4,
.swagger-ui .opblock-external-docs-wrapper h4,
.swagger-ui .opblock-title_normal h4 {
    font-size: 12px;
    margin: 0 0 5px;
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui .opblock-description-wrapper p,
.swagger-ui .opblock-external-docs-wrapper p,
.swagger-ui .opblock-title_normal p {
    font-size: 14px;
    margin: 0;
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui .responses-inner h4,
.swagger-ui .responses-inner h5 {
    font-size: 12px;
    margin: 10px 0 5px;
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui .response-col_status {
    font-size: 14px;
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui .response-col_links {
    padding-left: 2em;
    max-width: 40em;
    font-size: 14px;
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui .response-col_description__inner div.markdown,
.swagger-ui .response-col_description__inner div.renderedMarkdown p {
    font-size: 14px;
    font-style: italic;
    display: block;
    padding: 0
}

.swagger-ui .scheme-container .schemes>label {
    font-size: 12px;
    font-weight: 700;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: -20px 15px 0 0;
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui .loading-container .loading:after {
    font-size: 10px;
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 50%;
    content: "loading";
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui section h3 {
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui .btn {
    font-size: 14px;
    font-weight: 700;
    padding: 5px 23px;
    -webkit-transition: all .3s;
    transition: all .3s;
    border: 2px solid gray;
    border-radius: 4px;
    background: transparent;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui select {
    font-size: 14px;
    font-weight: 700;
    padding: 5px 40px 5px 10px;
    border: 2px solid #41444e;
    border-radius: 4px;
    background: #f7f7f7 url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyMCI+ICAgIDxwYXRoIGQ9Ik0xMy40MTggNy44NTljLjI3MS0uMjY4LjcwOS0uMjY4Ljk3OCAwIC4yNy4yNjguMjcyLjcwMSAwIC45NjlsLTMuOTA4IDMuODNjLS4yNy4yNjgtLjcwNy4yNjgtLjk3OSAwbC0zLjkwOC0zLjgzYy0uMjctLjI2Ny0uMjctLjcwMSAwLS45NjkuMjcxLS4yNjguNzA5LS4yNjguOTc4IDBMMTAgMTFsMy40MTgtMy4xNDF6Ii8+PC9zdmc+) right 10px center no-repeat;
    background-size: 20px;
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .25);
    font-family: sans-serif;
    color: #002b5a;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.swagger-ui label {
    font-size: 12px;
    font-weight: 700;
    margin: 0 0 5px;
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui textarea {
    font-size: 12px;
    width: 100%;
    min-height: 280px;
    padding: 10px;
    border: none;
    border-radius: 4px;
    outline: none;
    background: hsla(0, 0%, 100%, .8);
    font-family: monospace;
    font-weight: 600;
    color: #002b5a
}

.swagger-ui .checkbox p {
    font-weight: 400 !important;
    font-style: italic;
    margin: 0 !important;
    font-family: monospace;
    font-weight: 600;
    color: #002b5a
}

.swagger-ui .dialog-ux .modal-ux-content p {
    font-size: 12px;
    margin: 0 0 5px;
    color: #41444e;
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui .dialog-ux .modal-ux-content h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 15px 0 0;
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui .dialog-ux .modal-ux-header h3 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    padding: 0 20px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui .model {
    font-size: 12px;
    font-weight: 300;
    font-family: monospace;
    font-weight: 600;
    color: #002b5a
}

.swagger-ui .servers>label {
    font-size: 12px;
    margin: -20px 15px 0 0;
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui table.headers td {
    font-size: 12px;
    font-weight: 300;
    vertical-align: middle;
    font-family: monospace;
    font-weight: 600;
    color: #002b5a
}

.swagger-ui table thead tr td,
.swagger-ui table thead tr th {
    font-size: 12px;
    font-weight: 700;
    padding: 12px 0;
    text-align: left;
    border-bottom: 1px solid rgba(59, 65, 81, .2);
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui .parameter__name {
    font-size: 16px;
    font-weight: 400;
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui .info li,
.swagger-ui .info p,
.swagger-ui .info table {
    font-size: 14px;
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui .info h1,
.swagger-ui .info h2,
.swagger-ui .info h3,
.swagger-ui .info h4,
.swagger-ui .info h5 {
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui .info .base-url {
    font-size: 12px;
    font-weight: 300 !important;
    margin: 0;
    font-family: monospace;
    font-weight: 600;
    color: #002b5a
}

.swagger-ui .info .title {
    font-size: 36px;
    margin: 0;
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui .auth-container .errors {
    font-size: 12px;
    padding: 10px;
    border-radius: 4px;
    font-family: monospace;
    font-weight: 600;
    color: #002b5a
}

.swagger-ui .scopes h2 {
    font-size: 14px;
    font-family: sans-serif;
    color: #002b5a
}

.swagger-ui .errors-wrapper .errors h4 {
    font-size: 14px;
    margin: 0;
    font-family: monospace;
    font-weight: 600;
    color: #002b5a
}

.swagger-ui .errors-wrapper hgroup h4 {
    font-size: 20px;
    margin: 0;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-family: sans-serif;
    color: #002b5a
}

div.response-control-media-type.response-control-media-type--accept-controller small.response-control-media-type__accept-message {
    display: none;
}

tr.response td.response-col_links i {
    display: none;
}

tr.responses-header td.col.col_header.response-col_links {
    display: none;
}

div.response-col_description__inner div.renderedMarkdown p {
    margin-top: 0;
}

td.col_header.response-col_status,
td.col_header.response-col_description {
    font-family: sans-serif;
    font-size: 14px
}

div.opblock-description div.renderedMarkdown p code,
td.parameters-col_description div.renderedMarkdown p code {
    color:#007bb8
}
