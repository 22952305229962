/* Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0*/

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h1, h2, h3, h4, h5 {
  line-height: initial
}

.main-fragment {
  display: flex;
  min-height: 100%;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  overflow: auto;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 14px;
  color: lightgray;
  background-color: #1f1f1f;
  padding: .75em 0 .75em 0;
}

.footer-content p {
  text-align: center;
  vertical-align: middle;
}

#root {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.fixfloat:after {
  content: "";
  display: table;
  clear: both;
}

@keyframes App-logo-spin {
  from { transform: scale(1); }
  to { transform: scale(1.08); }
}

/* fix semantic-ui quirks */
.ui.negative.floating.message, .ui.attached.negative.floating.message {
  box-shadow: 
    0px 0px 0px 1px #E0B4B4 inset, 
    0px 2px 4px 0px rgba(34, 36, 38, 0.12), 
    0px 2px 10px 0px rgba(34, 36, 38, 0.15)
}

.ui.vertical.menu .menu .item {
  font-size: 0.9em;
}

.ui.vertical.menu .menu .menu .item {
  margin-left: 1em;
  font-size: 1em;
}

.ui.inverted.menu .menu a.item:hover {
  color: #ffffff;
}

.swagger-pre-footer-padding {
  padding-bottom: 3em;
}
